import type { VFC } from 'react';
import React, { Fragment, memo, useEffect, useContext } from 'react';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import { fg } from '@confluence/feature-gating';
import { InlineCommentsContext } from '@confluence/comment-context';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { LoadingPriority } from '@confluence/loadable';
import { markErrorAsHandled } from '@confluence/graphql';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { InlineCommentsLoader } from '@confluence/inline-comments/entry-points/InlineCommentsLoader';
import { InlineCommentsLoadingSkeleton } from '@confluence/inline-comments/entry-points/InlineCommentsLoadingSkeleton';
import { FocusedInlineComment } from '@confluence/inline-comments/entry-points/FocusedInlineComment';
import { FocusedInlineCommentQuery } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import {
	InlineCommentsQuery,
	OptimizedInlineCommentsQuery,
} from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';

export const ViewPageInlineComments: VFC<{
	contentId: string;
	loadingPriority: LoadingPriority | null;
	isFabricPage: boolean | undefined;
}> = memo(({ contentId, loadingPriority, isFabricPage }) => {
	const { editCommentId, replyToCommentId } = useInlineCommentQueryParams();
	const { getQueryParams } = useContext(RoutesContext);

	//COMMENTS-158 - focusedCommentId is getting retrieved here instead of using useInlineCommentQueryParams because the function is module replaced in SSR and the logic and does not work on SSR pages
	const { focusedCommentId: queryParamsFocusedCommentId } = getQueryParams();
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const focusedCommentId = queryParamsFocusedCommentId
		? (queryParamsFocusedCommentId as string)
		: undefined;

	/**
	 * Preload InlineCommentsQuery - We preload InlineCommentsQuery as part of next/packages/query-preloaders/src/index.ts,
	 * but its bestEffort Preloading - meaning if it exceeds the threshold it wont wait for query to finish
	 * Adding preloading here as well to ensure data is ready before first highlight click
	 */

	const inlineCommentsQueryToUse = fg('confluence_fe_call_new_inline_comments_query')
		? OptimizedInlineCommentsQuery
		: InlineCommentsQuery;
	const { error } = useQuery(inlineCommentsQueryToUse, {
		variables: { pageId: contentId },
		skip: !contentId,
	});

	/**
	 * Marking all graphql errors here as handled since we are only preloading query.
	 * The InlineComments component where the query data is used has exhaustive error handling
	 */
	if (error) {
		markErrorAsHandled(error);
	}

	const { data, error: focusedCommentError } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FocusedInlineCommentQuery,
		{
			variables: { commentId: focusedCommentId, contentId },
			fetchPolicy: 'cache-first',
			skip: !(contentId && focusedCommentId),
		},
	);

	if (focusedCommentError && isUnauthorizedError(focusedCommentError)) {
		markErrorAsHandled(focusedCommentError);
	}

	/* Only load Inline Comments when focusedCommentId is a Inline comment*/
	const isFocusedInlineComment = Boolean(data?.comments?.nodes?.[0]?.isInlineComment);

	const { firstHighlightClicked, shouldDisplaySidebar, toggleSidebar } =
		useContext(InlineCommentsContext);

	useEffect(() => {
		// Remaining query based scenarios that require inline comments to load immediately
		if (
			(window.__SSR_RENDERED__ && isFocusedInlineComment) ||
			isFocusedInlineComment ||
			replyToCommentId ||
			(editCommentId && shouldDisplaySidebar) ||
			(firstHighlightClicked && shouldDisplaySidebar)
		) {
			toggleSidebar(true);
		}
	}, [
		isFocusedInlineComment,
		replyToCommentId,
		editCommentId,
		firstHighlightClicked,
		toggleSidebar,
		shouldDisplaySidebar,
	]);

	/* Focused Comment SSR */
	if (process.env.REACT_SSR && isFocusedInlineComment) {
		return <FocusedInlineComment />;
	}

	/**
	 * On initial page load, shouldDisplaySidebar will usually be false allowing us to
	 * avoid loading inline comments unless needed. The three scenarios where we would
	 * explicitly load are:
	 * 1. Query Parameters
	 * 2. Click on a highlight
	 * 3. Create an inline comment
	 */
	return (
		<Fragment>
			{renderServerPlaceholder && <InlineCommentsLoadingSkeleton />}
			{/* Load on TinyMCE page to enable commenting */}
			{shouldDisplaySidebar && !isFabricPage && (
				<InlineCommentsLoader
					pageId={contentId}
					isFabricPage={Boolean(isFabricPage)}
					loadingPriority={loadingPriority || LoadingPriority.PAINT}
					focusedCommentId={focusedCommentId}
				/>
			)}
		</Fragment>
	);
});
